import { Link } from "react-router-dom";
import classes from "./BankCard.module.css";
import React from "react";
import {ImageWithFallback} from "../utils/imageWithFallback";

export interface BankProps {
  imgUrl: string;
  title: string;
  subtitle: string;
  bankUrl: string; //Bank uuid string
}

export function Bank({ imgUrl, title, subtitle, bankUrl }: BankProps) {
  return (
    <Link
      to={`/${bankUrl}/company-overview`}
      className={classes.bank}
      style={{ textDecoration: "none" }}
    >
      <div className={classes.bankinfo}>
        <ImageWithFallback src={imgUrl} fallbackSrc="./images/404NotFound.png" alt={title} className={classes.bankico}/>
        <div className={classes.bankTitle}>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
      </div>
      <svg
          width="1.6rem"
          height="1.6rem"
          viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7074 16.7074L12.7074 26.7074C12.6145 26.8004 12.5042 26.8741 12.3828 26.9243C12.2614 26.9746 12.1313 27.0005 11.9999 27.0005C11.8686 27.0005 11.7384 26.9746 11.617 26.9243C11.4957 26.8741 11.3854 26.8004 11.2924 26.7074C11.1995 26.6145 11.1258 26.5042 11.0756 26.3828C11.0253 26.2614 10.9994 26.1313 10.9994 25.9999C10.9994 25.8686 11.0253 25.7384 11.0756 25.6171C11.1258 25.4957 11.1995 25.3854 11.2924 25.2924L20.5862 15.9999L11.2924 6.70745C11.1048 6.5198 10.9994 6.26531 10.9994 5.99995C10.9994 5.73458 11.1048 5.48009 11.2924 5.29245C11.4801 5.1048 11.7346 4.99939 11.9999 4.99939C12.2653 4.99939 12.5198 5.1048 12.7074 5.29245L22.7074 15.2924C22.8004 15.3853 22.8742 15.4956 22.9245 15.617C22.9748 15.7384 23.0007 15.8685 23.0007 15.9999C23.0007 16.1314 22.9748 16.2615 22.9245 16.3829C22.8742 16.5043 22.8004 16.6146 22.7074 16.7074Z"
          fill="#86909C"
        />
      </svg>
    </Link>
  );
}
