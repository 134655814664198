import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import loadable from "@loadable/component";
import Layout from "./layout";
import withCompanyData from "./wrapper/CompanyWrapper";
import withAccountData from "./wrapper/AccountWrapper";
import NewAndReports from './views/newsAndReports/NewsAndReports';
import './views/styleguide.css';

const Home = loadable(() => import("./views/home/Home"));
const About = loadable(() => import("./views/about/About"));
const Search = loadable(() => import("./views/home/Search"));
const LoginForm = loadable(() => import("./views/account/LoginForm"));
const RegisterForm = loadable(() => import("./views/account/RegisterForm"));
const CompanyOverview = loadable(
  () => import("./views/company/CompanyOverview"),
);
const CompanyStaff = loadable(() => import("./views/company/CompanyStaff"));
const CompanyFinancial = loadable(
  () => import("./views/company/CompanyFinancial"),
);
const CompanyProduct = loadable(() => import("./views/company/CompanyProduct"));
const CompanyMarketing = loadable(
  () => import("./views/company/CompanyMarketing"),
);
const NotFound = loadable(() => import("./views/NotFound"));
const CompanyESG = loadable(() => import("./views/company/CompanyESG"));
const CompanyWeb3 = loadable(() => import("./views/company/CompanyWeb3"));
const CompanyTech = loadable(() => import("./views/company/CompanyTech"));
const Profile = loadable(() => import("./views/account/profile"));
const Compliance = loadable(() => import("./views/compliance/compliance"));
const LoginWithData = withAccountData(LoginForm);
const RegisterWithData = withAccountData(RegisterForm);
const CompanyOverviewWithData = withCompanyData(CompanyOverview);
const CompanyStaffWithData = withCompanyData(CompanyStaff);
const CompanyFinancialWithData = withCompanyData(CompanyFinancial);
const CompanyProductWithData = withCompanyData(CompanyProduct);
const CompanyMarketingWithData = withCompanyData(CompanyMarketing);
const CompanyESGWithData = withCompanyData(CompanyESG);
const CompanyWeb3WithData = withCompanyData(CompanyWeb3);
const CompanyTechWithData = withCompanyData(CompanyTech);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="/search" element={<Search />} />
          <Route path="/neobanker-homepage" element={<Home />} />
          <Route path="/AboutUs" element={<About />} />
          <Route path="/LoginForm" element={<LoginWithData />} />
          <Route path="/NewAndReports" element={<NewAndReports />} />
          <Route path="/RegisterForm" element={<RegisterWithData />} />
          <Route
            path="/:company_name/company-overview"
            element={<CompanyOverviewWithData />}
          />
          <Route
            path="/:company_name/company-staff"
            element={<CompanyStaffWithData />}
          />
          <Route
            path="/:company_name/company-financials"
            element={<CompanyFinancialWithData />}
          />
          <Route
            path="/:company_name/company-product"
            element={<CompanyProductWithData />}
          />
          <Route
            path="/:company_name/company-marketing"
            element={<CompanyMarketingWithData />}
          />
          <Route
            path="/:company_name/company-tech"
            element={<CompanyTechWithData />}
          />
          <Route
            path="/:company_name/company-esg"
            element={<CompanyESGWithData />}
          />
          <Route
            path="/:company_name/company-web3"
            element={<CompanyWeb3WithData />}
          />
          <Route 
            path="/profile"
            element={<Profile />}
          />
          <Route 
            path="/compliance"
            element={<Compliance />}
          />           
          {/*<Route*/}
          {/*  path="/:company_name/company-overview"*/}
          {/*  element={<CompanyOverview />}*/}
          {/*/>*/}
          {/*<Route path="/:company_name/company-staff" element={<CompanyStaff />} />*/}
          {/*<Route*/}
          {/*  path="/:company_name/company-financials"*/}
          {/*  element={<CompanyFinancial />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/:company_name/company-product"*/}
          {/*  element={<CompanyProduct />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/:company_name/company-marketing"*/}
          {/*  element={<CompanyMarketing />}*/}
          {/*/>*/}
          {/*<Route path="/:company_name/company-tech" element={<CompanyTech />} />*/}
          {/*<Route path="/:company_name/company-esg" element={<CompanyESG />} />*/}
          {/*<Route path="/:company_name/company-web3" element={<CompanyWeb3 />} />*/}

          {/*<Route path="*" element={<NotFound />} />*/}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
