import React from 'react';
import {
    NewsAndReportsItems
} from "./NewsAndReportsItems";
import styles from'./NewsAndReports.module.css';


function NewAndReports() {
    return (
        <div className={styles.index}>
            <NewsAndReportsItems />
        </div>
    );
}

export default NewAndReports;