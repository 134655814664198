import React from "react";
import classes from "./NewsCard.module.css";

export interface NewsCardProps {
  title: string;
  preview?:string;
  date: string;
  imgUrl?: string;
  url?: string;
  tags?: string[];
  isVertical?: boolean;
  displayImg?: boolean;
}

/**
 * This is a card component for displaying news
 * @param title - The title of the news
 * @param date - The date of the news
 * @param imgUrl - The image url of the news
 * @param url - The url of the news
 * @param tags - The tags of the news
 * @param isVertical - Whether the card is vertical
 * @param displayImg - Whether the card displays image
 * */
export function NewsCard({
  title,
  preview,
  date,
  imgUrl,
  url = `http://www.google.com/search?q=${title}`,
  tags,
  isVertical = false,
  displayImg = true,
}: NewsCardProps) {
  /*
  if (isVertical) {
    return (
      
      <a
        href={url}
        target={"_blank"}
        rel={"noopener noreferrer"}
        style={{ textDecoration: "none" }}
      >
        <div className={classes.card_vertical}>
          <div className={classes.tags}>
            {tags &&
              tags.map((tag, index) => (
                <div key={index} className={classes.tag}>
                  {tag}
                </div>
              ))}
          </div>
          {displayImg && (
            <div className={classes.card_img_vertical}>
              <img src={imgUrl} alt={title} />
            </div>
          )}
          <div className={classes.date}>{date}</div>
          <div className={classes.card_title}>{title}</div>
        </div>
      </a>
    );
  }
    */

  function getImageProxyUrl(imgUrl: string) {
    //data: 
    if (imgUrl.startsWith("data:")) {
      return imgUrl
    }
    const location = window.location
    // support for image urls starting with /api/attachment
    // most likely for gstatic urls
    if (imgUrl.startsWith("/api/attachment")) {
      return `${location.protocol}//${location.hostname}:3001/api/sys/permission${imgUrl}`
    }
    // others urls will be proxied
    return `${location.protocol}//${location.hostname}:3001/api/sys/images/${btoa(imgUrl).replace(/\//g, "_").replace(/\+/g, "-")}`
  }

  return (
    <a
      href={url}
      target={"_blank"}
      rel={"noopener noreferrer"}
      style={{ textDecoration: "none" }}
    >
      <div className={displayImg ? classes.card : classes.news_img}>
      {displayImg && (
          <img crossOrigin="anonymous" className={classes.news_img} src={imgUrl ? getImageProxyUrl(imgUrl) : '/images/newsDefaultImg.png'} alt={title} onError={(e) => {const target = e.target as HTMLImageElement; target.src = '/images/newsDefaultImg.png'; }}/>
      )}
        
        <div className={classes.card_content}>
          <div className={classes.card_title}>{title}</div>
          <div className={classes.card_preview}>{preview}</div>
        
          <div className={classes.footnote_container}>
          <div className={classes.footnote}>
            <div className={classes.tags}>
              {tags &&
                tags.map((tag, index) => (
                  <div key={index} className={classes.tag}>
                    {tag}
                  </div>
                ))}
            </div>
            <div className={classes.date}>{date}</div>
          </div>
          </div>
        </div>

       
         
          

      </div>
    </a>
  );
}