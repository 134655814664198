import React,{useEffect,useState} from "react";
import classes from "./TabAndPaginationBox.module.css";
import {useNavigate, useSearchParams} from "react-router-dom";
/**
 * TabBox
 * this is a component that contains a tab bar and a tab content box
 * the tab bar is a horizontal bar that contains a list of tab item,
 * tab items in the tab bar is in pink square, underlined in bold red style
 * */
interface TabBoxProps {
  tabItems: TabItemProps[];
  tabEndItem?: React.ReactNode;
}

interface TabItemProps {
  title: string;
  content: React.ReactNode;
}

export function TabBox({ tabItems, tabEndItem }: TabBoxProps) {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className={classes.tabBox}>
      <TabBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabItems={tabItems}
        tabEndItem={tabEndItem}
      />
      <TabContent activeTab={activeTab} tabItems={tabItems} />
    </div>
  );
}

interface TabBarProps {
  activeTab: number;
  setActiveTab: (tabIndex: number) => void;
  tabItems: TabItemProps[];
  tabEndItem?: React.ReactNode;
}

function TabBar({
  activeTab,
  setActiveTab,
  tabItems,
  tabEndItem,
}: TabBarProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className={classes.tabBar}>
        {tabItems.map((item, index) => {
          return (
            <div
              className={
                activeTab === index ? classes.activeTab : classes.inactiveTab
              }
              onClick={() => setActiveTab(index)}
              key={item.title}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      <div>{tabEndItem}</div>
    </div>
  );
}

interface TabContentProps {
  activeTab: number;
  tabItems: TabItemProps[];
}

function TabContent({ activeTab, tabItems }: TabContentProps) {
  return (
    <div className={classes.tabContent}>{tabItems[activeTab].content}</div>
  );
}

/**
 * this is a pagination component, the default page number is 1, and the default page size is 3
 * user can click load more button to load more data,and the page size will increase by 3
 * user can click load less button to load less data, and the page number will decrease by 3
 * user can click the page number button to jump to the page
 * */
interface PaginationBoxProps {
  children: React.ReactNode;
  //页面内子元素是不是竖着排列，默认是竖着排列的，不是竖着排列的话就是横着三个一排排列的
  isVertical?: boolean;
  initPageSize?: number;
  totalPages:number;
  inPage:number;
}

export function PaginationBox({
  children,
  isVertical = true,
  initPageSize = 3,
  totalPages,
  inPage,
}: PaginationBoxProps) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = React.useState(initPageSize);
  const [totalPage, setTotalPage] = React.useState(totalPages);

  React.useEffect(() => {
    setTotalPage(totalPages);
   /*  setPage(inPage); */
  }, [children, pageSize]);

  function loadMore() {
    if (pageSize <= React.Children.count(children)) {
      setPageSize(pageSize + 3);
      setTotalPage(Math.ceil(React.Children.count(children) / (pageSize + 3)));
      setPage(1);
    }
  }

  function loadLess() {
    if (pageSize - 3 >= 3) {
      setPageSize(pageSize - 3);
      setTotalPage(Math.ceil(React.Children.count(children) / (pageSize - 3)));
      setPage(1);
    }
  }

/*   function jumpToPage(pageIndex: number) {
    setPage(Number(query));
  } */


  return (
    <div className={classes.paginationBox}>
      <div
        style={
          isVertical
            ? {
                display: "flex",
                flexDirection: "column",
              }
            : {
                display: "grid",
                width: "100%",
                overflow: "hidden",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "50px",
              }
        }
      >
        {React.Children.toArray(children)
          .slice((page - 1) * pageSize, page * pageSize)
          .map((child, index) => {
            return (
              <div key={index} style={{ maxWidth: "100%" }}>
                {child}
              </div>
            );
          })}
      </div>

{/*       <div className={classes.paginationBoxFooter}>
        <div className={classes.paginationBoxFooterButtons}>
          <div className={classes.indexBox}>
          {totalPage > 1 && (
            <>
            <button className={classes.arrowButton} onClick={() => setPage(1)}>&#171;</button>
            <button className={classes.arrowButton} onClick={() => {const prevPage = page - 1; setPage(prevPage < 1 ? 1 : prevPage)}}>&#8249;</button>
            </>
            )}
            {Array.from({ length: Math.min(totalPage,5) }, (_, index) =>  {
              if (page - 3 <= 0) {
                return index + 1;
              } else if (page + 3 > totalPage) {
                return totalPage - 4 + index;
              } else {
                return page - 3 + index + 1;
              }
              }).map(
              (item, index) => {
                return (
                  <div
                    className={
                      item === page ? classes.activeIndex : classes.index
                    }
                    onClick={() => setPage(item)}
                    key={item}
                  >
                    {item}
                  </div>
                );
              },
            )}
           {totalPage > 1 && (
            <>
            <button className={classes.arrowButton} onClick={() => {const nextPage = page + 1; setPage(nextPage > totalPage ? 1 : nextPage)}}>&#8250;</button>
            <button className={classes.arrowButton} onClick={() => setPage(totalPage)}>&#187;</button>
            </>
            )}
          </div>
        </div>
      </div> */}

    </div>
  );
}

/**
 * this is a component that contains a tab bar and a tab content box,
 * the tab bar is a horizontal bar that contains a list of tab items,
 * tab items in the tab bar is in pink oval style
 */

export function TabBox2ndStyle({ tabItems, tabEndItem }: TabBoxProps) {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className={classes.tabBox}>
      <TabBar2ndStyle
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabItems={tabItems}
        tabEndItem={tabEndItem}
      />
      <TabContent activeTab={activeTab} tabItems={tabItems} />
    </div>
  );
}

function TabBar2ndStyle({
  activeTab,
  setActiveTab,
  tabItems,
  tabEndItem,
}: TabBarProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className={classes.tabBar2ndStyle}>
        {tabItems.map((item, index) => {
          return (
            <div
              className={
                activeTab === index
                  ? classes.activeTab2ndStyle
                  : classes.inactiveTab2ndStyle
              }
              onClick={() => setActiveTab(index)}
              key={item.title}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      <div>{tabEndItem}</div>
    </div>
  );
}
