import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";
import { SearchInput } from "../views/home/HomeItem";
import ProfileMenu from '../components/ProfileMenu';

const Header = () => {
  const [navbarBg, setNavbarBg] = useState(false);
  const [expendMenu, setExpendMenu] = useState<string | null>(null);
  const resourcesBtnRef = useRef(null);

  const handleClose = () => {
    setExpendMenu(null);
  };

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbarBg(true);
    } else {
      setNavbarBg(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  });

  const searchStyle: React.CSSProperties = {
    height: 30, //83px
    width: 60, //30%
    backgroundPosition: 11,
    backgroundSize: 14,
    fontSize: 11,
    paddingLeft: 30,
  };

  return (
      <header
          className={classes.header}
          style={{
            position: navbarBg ? "fixed" : "relative",
          }}
      >
        <nav className={classes.navbar}>
          <Link to={"/"} className={classes.brand}>
            <img src={"/images/Logo(H190x70).png"} alt="Neobanker"
                 style={{width: '100px', height: 'auto', marginLeft: '0.6rem'}}/>
          </Link>
          <div className={classes.navbarItemBox}>
            <div className={classes.navbarItem}>
              <Link to={"/search?query=all"}>Banks Statistics</Link>
            </div>

            <div className={classes.navbarItem}>
                        <Link to={"/NewAndReports"}>News & Reports</Link>
            </div>
            <div className={classes.navbarItem}>
                        <Link to={"/compliance"}>Compliance</Link>
            </div>
          </div>
        </nav>
        <div className={classes.widgetBox}>
          <ProfileMenu/>
          <SearchInput placeholder={""} style={searchStyle}/>
        </div>
      </header>
  );
};

export default Header;
