import React, { useCallback, useState, FormEvent, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classes from "./Footer.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleLinkClick = useCallback((path: string) => {
        if (pathname === path) {
            window.scrollTo(0, 0);
        } else {
            navigate(path);
            window.scrollTo(0, 0);
        }
    }, [pathname, history]);
    
    //the follow is for AIChatbot
    const [openChatBot, setOpenChatBot] = useState(false);
  
    const toggleAIChatBot = () =>{
      setOpenChatBot((prevState) => !prevState);
    };

    type Message = {
      content: string;
      sender: string;
      timestamp: string;
    };
    const [typing, setTyping] = useState(false);
    
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);
    

      const handleInputChange = (event:any) => {
        setInputValue(event.target.value);
      };

      const handleSendWithKeyboard = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          handleSendMessage(e as unknown as FormEvent<HTMLFormElement>);
        }
      };
      
      const handleSendMessage = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
          const newMessage: Message = {
            sender: 'user',
            content: inputValue,
            timestamp: new Date().toISOString(),
          };
      
          const newMessages = [...messages, newMessage];
          setMessages(newMessages);
          setInputValue('');
          setTyping(true);
      
          await processMessageToAI(newMessage.content);
        }
      };
      
      const processMessageToAI = async (userQuery: string) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_AI}/api/generate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content: userQuery }),
          });
      
          if (response.ok) {
            const data = await response.json();
            console.log('Send Message successful!', data);
            handleBotMessage(data.response);
          } else {
            throw new Error('Send message failed');
          }
        } catch (error) {
          console.error(error);
          window.alert('Message failed to send.');
        }
      
        setTyping(false);
      };
      
      useEffect(() => {
        const fetchBotResponse = async () => {
          if (typing) {
            try {
              const response = await fetch(`${process.env.REACT_APP_AI}/api/generate`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: messages[messages.length - 1].content }),
              });
      
              if (response.ok) {
                const data = await response.json();
                console.log('Bot response:', data.response);
                handleBotMessage(data.response);
              } else {
                throw new Error('Network response was not ok');
              }
            } catch (error) {
              console.error(error);
            }
            setTyping(false);
          }
        };
      
        fetchBotResponse();
      }, [typing, messages]);
      
      const handleBotMessage = (dataContent: string) => {
        const newMessage: Message = {
          sender: 'bot',
          content: dataContent,
          timestamp: new Date().toISOString(),
        };
        const newMessages = [...messages, newMessage];
        setMessages(newMessages);
      };


    return (
        <footer className={`${classes.footer} d-flex flex-column justify-content-center align-items-center py-2`}>
            <nav className="d-flex justify-content-center align-items-center mb-3">
                <Link to={"/"} className="me-4">
                    <img src={"/images/Logo(H190x70).png"} alt="Neobanker" />
                </Link>
                <div className="d-flex justify-content-center align-items-center">
                    <div className={classes.navbarItem}>
                        <Link to="/" onClick={() => handleLinkClick("/")}>
                            Home
                        </Link>
                    </div>
                    <div className={classes.navbarItem}>
                        <Link to="/AboutUs" onClick={() => handleLinkClick("/AboutUs")}>
                            About Us
                        </Link>
                    </div>
                    {/* <div className={classes.navbarItem}>
                        <Link to={"/TermsOfUse"}>Terms of Use</Link>
                    </div> */}
                    {/*
                    <div className={classes.navbarItem}>
                        <Link to={"/Contact"}>Contact</Link>
                    </div>
                    */}
                </div>
            </nav>
            <div>
                All Rights Reserved© {new Date().getFullYear()} Digital Financial Services Research Center Limited
            </div>

    {/* the follow is AIChatBot */}
     <div>
      {openChatBot ?(
      <div>
        <div className={classes.main_container}>
          <div className={classes.chatbot_header}>
          <img src="/images/chatbotUILogo.png"/>
              <div className={classes.header_right}>
                <div className={classes.insightful_precise}>Insightful, Precise</div>
                <div className={classes.neobank_statistic}>Neobank Statistic</div>
              </div>
          </div>

          <div className={classes.chatbot_messages}>
                    <div className={classes.botMessageBubble}><span className={classes.banker_gpt}>BankerGPT</span>
                    Hello! I am your private virtual assistance BankerGPT. These are Neobanks news today for you! What data can I provide you to help you?
                    </div>
                {messages.map((message, i) => {
                  return <div key={i}>
                  {message.sender === 'user' ? (
                    <div className={classes.userMessageBubble}><span className={classes.banker_gpt}>User</span>
                    {message.content}
                 </div>
                  ) : (
                    <div className={classes.botMessageBubble}><span className={classes.banker_gpt}>BankerGPT</span>
                      {message.content}
                    </div>
                  )}
                </div>
                })}
                
          </div>

      <div className={classes.bottom_container}>          
        <div className={classes.li_tag}>
          <form onSubmit={handleSendMessage}>
            <input type="hidden" value="Virtual Bank"/>
            <button className={classes.keyword} onClick={() => setInputValue("Virtual Bank")}> Virtual Bank</button>
            </form>
            <form onSubmit={handleSendMessage}>
            <input type="hidden" value="WEB3.0"/>
            <button className={classes.keyword} onClick={() => setInputValue("WEB3.0")}> WEB3.0</button>
            </form>
            <form onSubmit={handleSendMessage}>
            <input type="hidden" value="Payment Tech"/>
            <button className={classes.keyword} onClick={() => setInputValue("Payment Tech")}>Payment Tech</button>
            </form>
            <form onSubmit={handleSendMessage}>
            <input type="hidden" value="Dapp"/>
            <button className={classes.keyword} onClick={() => setInputValue("Dapp")}>Dapp</button>
            </form>
            <form onSubmit={handleSendMessage}>
            <input type="hidden" value="New App"/>
            <button className={classes.keyword} onClick={() => setInputValue("New App")}>New App</button>
            </form>
            
        </div>
        <div className={classes.chatbotMessage}>  
          <form onSubmit={handleSendMessage}>
              <input placeholder="Ask Anythink" onChange={handleInputChange} value={inputValue} onKeyDown={handleSendWithKeyboard}/>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>

{/*       <div className={classes.frame_a}>
        <div className={classes.frame_b}>
          <div className={classes.image}></div>
          <div className={classes.frame_c}>
            <span className={classes.hong_kong_za_bank_offers_crypto_conversions}>
              Hong Kong ZA Bank Offers Crypto Conversions, allows crypto
              exchanges clients to withdraw HKD, RMB or USD</span>
            
          </div>
        </div>
      </div>
      <div className={classes.frame_14}></div> */}
    </div>
      </div>
      ): (   
        <div>{/* empty */}</div>      
      )}

      <button className={classes.vector} onClick={toggleAIChatBot}/>
    </div>
        </footer>
        
    );
};

export default Footer;
