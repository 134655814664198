import React, { useState, useContext } from 'react';
import { ReactComponent as ProfileIcon } from '../icons/profile.svg';
import styles from './ProfileMenu.module.css';
import { Link } from "react-router-dom";
import { AccountContext} from '../context/AccountContext';

const ProfileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useContext(AccountContext);

  const handlePopoverOpen = () => {
    setIsOpen(true);
  };

  const handlePopoverClose = () => {
    setIsOpen(false);
  };

  const handleLogin = () => {
    console.log('Login button clicked');
  };

  const handleRegister = () => {
    console.log('Register button clicked');
  };

  const handleITSolution = () => {
    console.log('IT Solution button clicked');
    /* alert(isLoggedIn); */
  };

  return (
    <div className={styles.profileMenu} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <button
        className={styles.profileIconButton}
        aria-label="account of current user"
        aria-haspopup="true"
      >
        <ProfileIcon className={styles.profileIcon} />
      </button>
      {isOpen && (
        <div className={styles.profilePopover}>
          {isLoggedIn ? (
            <Link to={"/profile"}>
              <button className={styles.profilePopoverButton}>Profile</button>
            </Link>
          ) : (
            <>
              <Link to={"/LoginForm"}>
                <button className={styles.profilePopoverButton} onClick={handleLogin}>Login</button>
              </Link>
              <Link to={"/RegisterForm"}>
                <button className={styles.profilePopoverButton} onClick={handleRegister}>Register</button>
              </Link>
            </>
          )}
          <button className={styles.profilePopoverButton} onClick={handleITSolution}>IT Solution</button>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;