import React from "react";
import classes from "./SectionBox.module.css";
import { Link } from "react-router-dom";

/**
 * A section box is a box that contains a heading and content
 * takes <b>66.66%</b> of the width of the parent container
 * @param sectionWidth - The width of the section, the default is "66.66%"
 * @param heading - The heading of the section
 * @param headingFontSize - The font size of the heading, the default is "40px"
 * @param headingSuffix - The suffix of the heading, will be displayed after the heading, same color, smaller font size
 * @param subheading - The subheading of the section, usually a short description of the section
 * @param children - The content of the section
 * @param button - redirect button at the end of the heading
 */
interface SectionProps {
  sectionWidth?: string;
  heading?: string;
  headingFontSize?: string;
  headingSuffix?: string;
  upTop?: string;
  subheading?: string;
  subheadingStyle?: React.CSSProperties;
  children?: React.ReactNode;
  button?: React.ReactNode;
  upComing?: boolean;
  isHaveTop?: boolean;
  id?: string;
}

export function Section({
  sectionWidth = "66.66%",
  heading,
  headingFontSize = "2.125rem",
  upTop,
  headingSuffix,
  subheading,
  subheadingStyle = {
    color: "#FFA8A8",
    fontSize: '1.5rem',
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "225%",
  },
  children,
  button,
  upComing = false,
  id,
}: SectionProps) {
  switch (id) {
    case 'companySection':
      upTop = '14.7rem'
      break;
    case 'graphs':
      upTop = '8rem'
      break;
    default:
      upTop = '3rem'
      break;
  }
  return (
    <div id={id} style={{ width: sectionWidth }} className={classes.section}>
      <div className={classes.header}>
        <div>
          <h1 style={{ fontSize: headingFontSize }}>
            {heading}
            <span>{headingSuffix}</span>
          </h1>
          <h2 style={subheadingStyle}>{subheading}</h2>
        </div>
        {button}
      </div>
      {upComing && (
        <div className={classes.overlay} style={{top: upTop}}>
          <h1>
            <svg
              width="2.5rem"
              height="2.5rem"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.4375 26.5625V15.625C23.4375 15.2106 23.6021 14.8132 23.8952 14.5202C24.1882 14.2271 24.5856 14.0625 25 14.0625C25.4144 14.0625 25.8118 14.2271 26.1049 14.5202C26.3979 14.8132 26.5625 15.2106 26.5625 15.625V26.5625C26.5625 26.9769 26.3979 27.3743 26.1049 27.6674C25.8118 27.9604 25.4144 28.125 25 28.125C24.5856 28.125 24.1882 27.9604 23.8952 27.6674C23.6021 27.3743 23.4375 26.9769 23.4375 26.5625ZM45.3125 17.8809V32.1192C45.3138 32.5297 45.2335 32.9364 45.0763 33.3156C44.919 33.6948 44.688 34.039 44.3965 34.3281L34.3281 44.3965C34.039 44.688 33.6948 44.919 33.3156 45.0763C32.9364 45.2335 32.5297 45.3138 32.1192 45.3125H17.8809C17.4703 45.3138 17.0636 45.2335 16.6844 45.0763C16.3052 44.919 15.961 44.688 15.6719 44.3965L5.60353 34.3281C5.31209 34.039 5.08102 33.6948 4.92376 33.3156C4.76651 32.9364 4.6862 32.5297 4.68752 32.1192V17.8809C4.6862 17.4703 4.76651 17.0636 4.92376 16.6844C5.08102 16.3052 5.31209 15.961 5.60353 15.6719L15.6719 5.60353C15.961 5.31209 16.3052 5.08102 16.6844 4.92376C17.0636 4.76651 17.4703 4.6862 17.8809 4.68752H32.1192C32.5297 4.6862 32.9364 4.76651 33.3156 4.92376C33.6948 5.08102 34.039 5.31209 34.3281 5.60353L44.3965 15.6719C44.688 15.961 44.919 16.3052 45.0763 16.6844C45.2335 17.0636 45.3138 17.4703 45.3125 17.8809ZM25 31.25C24.5365 31.25 24.0833 31.3875 23.6979 31.645C23.3125 31.9025 23.0121 32.2686 22.8347 32.6969C22.6573 33.1251 22.6109 33.5964 22.7013 34.051C22.7917 34.5057 23.015 34.9233 23.3427 35.251C23.6705 35.5788 24.0881 35.802 24.5428 35.8925C24.9974 35.9829 25.4687 35.9365 25.8969 35.7591C26.3252 35.5817 26.6912 35.2813 26.9488 34.8959C27.2063 34.5105 27.3438 34.0573 27.3438 33.5938C27.3438 32.9722 27.0968 32.376 26.6573 31.9365C26.2178 31.497 25.6216 31.25 25 31.25Z"
                fill="#FBD0D6"
              />
              <path
                d="M23.4375 26.5625V15.625C23.4375 15.2106 23.6021 14.8132 23.8952 14.5202C24.1882 14.2271 24.5856 14.0625 25 14.0625C25.4144 14.0625 25.8118 14.2271 26.1049 14.5202C26.3979 14.8132 26.5625 15.2106 26.5625 15.625V26.5625C26.5625 26.9769 26.3979 27.3743 26.1049 27.6674C25.8118 27.9604 25.4144 28.125 25 28.125C24.5856 28.125 24.1882 27.9604 23.8952 27.6674C23.6021 27.3743 23.4375 26.9769 23.4375 26.5625ZM45.3125 17.8809V32.1192C45.3138 32.5297 45.2335 32.9364 45.0763 33.3156C44.919 33.6948 44.6879 34.039 44.3965 34.3281L34.3281 44.3965C34.039 44.6879 33.6948 44.919 33.3156 45.0763C32.9364 45.2335 32.5297 45.3138 32.1192 45.3125H17.8809C17.4703 45.3138 17.0636 45.2335 16.6844 45.0763C16.3052 44.919 15.961 44.6879 15.6719 44.3965L5.60353 34.3281C5.31209 34.039 5.08102 33.6948 4.92376 33.3156C4.76651 32.9364 4.6862 32.5297 4.68752 32.1192V17.8809C4.6862 17.4703 4.76651 17.0636 4.92376 16.6844C5.08102 16.3052 5.31209 15.961 5.60353 15.6719L15.6719 5.60353C15.961 5.31209 16.3052 5.08102 16.6844 4.92376C17.0636 4.76651 17.4703 4.6862 17.8809 4.68752H32.1192C32.5297 4.6862 32.9364 4.76651 33.3156 4.92376C33.6948 5.08102 34.039 5.31209 34.3281 5.60353L44.3965 15.6719C44.6879 15.961 44.919 16.3052 45.0763 16.6844C45.2335 17.0636 45.3138 17.4703 45.3125 17.8809ZM42.1875 17.8809L32.1192 7.81252H17.8809L7.81252 17.8809V32.1192L17.8809 42.1875H32.1192L42.1875 32.1192V17.8809ZM25 31.25C24.5365 31.25 24.0833 31.3875 23.6979 31.645C23.3125 31.9025 23.0121 32.2686 22.8347 32.6969C22.6573 33.1251 22.6109 33.5964 22.7013 34.051C22.7917 34.5056 23.015 34.9233 23.3427 35.251C23.6705 35.5788 24.0881 35.802 24.5428 35.8925C24.9974 35.9829 25.4687 35.9365 25.8969 35.7591C26.3252 35.5817 26.6912 35.2813 26.9488 34.8959C27.2063 34.5105 27.3438 34.0573 27.3438 33.5938C27.3438 32.9722 27.0968 32.376 26.6573 31.9365C26.2178 31.4969 25.6216 31.25 25 31.25Z"
                fill="#4F050E"
              />
            </svg>
            Upcoming Feature
          </h1>
          <Link to={"/AboutUs"}></Link>
        </div>
      )}
      {children}
    </div>
  );
}

/**
 * Subsection is a section that takes <b>100%</b> of the width of the parent container
 * @param heading - The heading of the subsection
 * @param children - The content of the subsection
 * @param button - redirect button at the end of the heading
 * */
interface SubsectionProps {
  heading: string;
  children?: React.ReactNode;
  button?: React.ReactNode;
}

export function Subsection({ heading, children, button }: SubsectionProps) {
  return (
    <div className={classes.subsection}>
      <div className={classes.header}>
        <h1>{heading}</h1>
        {button}
      </div>
      {children}
    </div>
  );
}

/**
 * A vertical divided section is a section that takes 100% of the width of the parent container, the section is divided into two parts
 * @param leftNode - The content of the left part
 * @param rightNode - The content of the right part
 * */
interface VerticalDividedSectionProps {
  leftNode: React.ReactNode;
  rightNode: React.ReactNode;
}

export function VerticalDividedSection({
  leftNode,
  rightNode,
}: VerticalDividedSectionProps) {
  return (
    <div className={classes.verticalDividedSection}>
      <div className={classes.leftNode}>{leftNode}</div>

      <div className={classes.verticalLine}></div>

      <div className={classes.rightNode}>{rightNode}</div>
    </div>
  );
}
/**
 * miniSection is a section that takes 100% of the width of the parent container
 * it will have a smaller heading font size and lighter font color
 * @param heading - The heading of the subsection
 * @param children - The content of the subsection
 * */
interface MiniSectionProps {
  heading: string;
  children?: React.ReactNode;
}

export function MiniSection({ heading, children }: MiniSectionProps) {
  return (
    <div className={classes.miniSection}>
      <h1>{heading}</h1>
      {children}
    </div>
  );
}

interface WideContentSectionBoxProps {
  heading?: string;
  headingFontSize?: string;
  subheading?: string;
  children?: React.ReactNode;
  background?: string;
}

export function WideContentSectionBox({
  background,
  heading,
  headingFontSize,
  subheading,
  children,
}: WideContentSectionBoxProps) {
  headingFontSize = headingFontSize || "40px";
  background = background || "#F5F5F5";
  return (
    <div
      className={classes.wideContentSectionBox}
      style={{ background: background }}
    >
      <div className={classes.wider__header}>
        <h1 style={{ fontSize: headingFontSize }}>{heading}</h1>
        <h2>{subheading}</h2>
      </div>
      {children}
    </div>
  );
}
