import React, { useState, useEffect } from 'react';

interface ImageWithFallbackProps {
  src: string;
  fallbackSrc: string;
  alt: string;
  className?: string;
}

export function ImageWithFallback({ src, fallbackSrc, alt, className }: ImageWithFallbackProps) {
  const [imgSrc, setImgSrc] = useState(src);

  // Update imgSrc whenever the src prop changes
  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  return <img src={imgSrc} alt={alt} onError={onError} className={className} />;
}
