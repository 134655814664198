import React from "react";
import classes from "./LoadingSign.module.css";

export default function LoadingSign() {
  return (
    <div className={classes.loading}>
      Loading Data...
      <div className={classes.spinner}></div>
    </div>
  );
}
