import React, { useEffect, useState } from "react";
import {
    AccountContext,
    Account
    } from "../context/AccountContext";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import LoadingSign from "../components/LoadingSign";

export default function withAccountData(Component: any) {
    return function WrapperComponent(props: any) {
        const [account, setAccount] = useState<Account | null>(null);
        const { email } = useParams<{ email: string }>();
        const [isLoggedIn, setIsLoggedIn] = useState(false);
        const navigate = useNavigate();
        useEffect(() => {
            if (email === undefined) {
                return; // 如果 email 为 undefined，直接返回，不执行后续代码
            }
            // Get Account
            fetch(`${process.env.REACT_APP_API_URL}/account/${email}/get`)
            .then(response => {
                if (response.ok) {
                return response.json();
                } else {
                throw new Error("Account not found");
                }
            })
            .then(data => setAccount(data))
            .catch(error => console.error(error));
        }, [email]);

        // Login Account
        const login = (account: Account) => {
            // 邮箱验证正则表达式
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!emailRegex.test(account.email)) {
                alert('请输入有效的电子邮件地址');
                return;
            }

            fetch(`${process.env.REACT_APP_API_URL}/account/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(account),
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Login failed");
                }
            })
            .then(data => {
                setAccount(data);
                setIsLoggedIn(true);
                alert('Login is successful!');
                navigate('/profile');
            })
            .catch(error => {
                console.error(error);
                window.alert('Incorrect user password or email address');
            });
        };

        // Register Account
        const register = (account: Account) => {
            // 邮箱验证正则表达式
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!emailRegex.test(account.email)) {
                alert('请输入有效的电子邮件地址');
                return;
            }
 
            // 检查用户名长度
            if (account.userName.length < 6 || account.userName.length > 16) {
                alert('用户名长度必须在6到16个字符之间');
                return;
            }

            // 检查密码长度和复杂性
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/;

            if (!passwordRegex.test(account.passWord)) {
                alert('密码必须由6-20个字符组成，包括至少一个大写字母，一个小写字母和一个数字');
                return;
            }

            // 电话号码验证正则表达式
            const phoneRegex = /^\d{8}$/;

            if (!phoneRegex.test(account.phoneNumber)) {
                alert('请输入有效的电话号码');
                return;
            }

            fetch(`${process.env.REACT_APP_API_URL}/account/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(account),
            })
            .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Registration failed");
            }
            })
            .then(data => {
                setAccount(data);
                alert('Registration successful! Please log in.');
            })
            .catch(error => {
                console.error(error);
                window.alert('Email address already exists. Please log in.');
            });
        };

        // Delete Account
        const deleteAccount = (email: string) => {
            fetch(`${process.env.REACT_APP_API_URL}/account/${email}/delete`, {
            method: 'DELETE',
            })
            .then(response => {
            if (response.ok) {
                setAccount(null);
            } else {
                throw new Error("Delete failed");
            }
            })
            .catch(error => console.error(error));
        };

        // Reset Password
        const resetPassword = (email: string, newPassword: string) => {
            fetch(`${process.env.REACT_APP_API_URL}/account/${email}/reset-password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ passWord: newPassword }),
            })
            .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Reset password failed");
            }
            })
            .then(data => setAccount(data))
            .catch(error => console.error(error));
        };

        //if (!account) {
        //    return <LoadingSign />;
        //}

        return (
            <AccountContext.Provider value={{ account, login, register, deleteAccount, resetPassword, isLoggedIn}}>
            <Component {...props} />
            </AccountContext.Provider>
        );
    }
}

