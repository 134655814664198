import React, { useEffect } from "react";
import {
  CompanyContext,
  CompanyData,
  initState,
} from "../context/CompanyContext";
import { useParams } from "react-router-dom";
import LoadingSign from "../components/LoadingSign";

export default function withCompanyData(Component: any) {
  return function WrapperComponent(props: any) {
    const [data, setData] = React.useState<CompanyData>(initState);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { company_name } = useParams<{ company_name: string }>();
    useEffect(() => {
      const fetchCompanyData = async () => {
        setLoading(true);

        try {
          // for testing: za bank's uuid --- d3459d56-1380-49b3-9e0d-0de6c94103f3
          const company_uuid_text = await fetch(
            `${process.env.REACT_APP_API_URL}/company/${company_name}/getId`,
          );
          let company_uuid = await company_uuid_text.text();
          company_uuid = company_uuid.replace(/"/g, "");

          const company_data_json = await fetch(
            `${process.env.REACT_APP_API_URL}/company/${company_uuid}/overview`,
          );
          const company_data: CompanyData = await company_data_json.json();

          setData(company_data);
        } catch (error) {
          console.log("Error fetch data from backend", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCompanyData();
    }, [company_name]);

    if (loading) {
      return <LoadingSign />;
    }

    return (
      <CompanyContext.Provider value={data}>
        <Component {...props} />
      </CompanyContext.Provider>
    );
  };
}
