import React, { createContext, useContext, useState } from 'react';

export interface Account {
  email: string;
  userName: string;
  phoneNumber: string;
  passWord: string;
  role: string;
  status: string;
}

export interface AccountContextType {
  account: Account | null;
  login: (account: Account) => void;
  register: (account: Account) => void;
  deleteAccount: (email: string) => void;
  resetPassword: (email: string, newPassword: string) => void;
  isLoggedIn: boolean;
}

const defaultAccountContextValue: AccountContextType = {
  account: null, // 默认账户为null
  login: () => {}, // 空实现的登录方法
  register: () => {}, // 空实现的注册方法
  deleteAccount: () => {}, // 空实现的删除账户方法
  resetPassword: () => {}, // 空实现的重置密码方法
  isLoggedIn: false, // 默认未登录
};

export const AccountContext = React.createContext<AccountContextType>(defaultAccountContextValue);
